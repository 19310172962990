import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import FormWrapper from "../components/SignUpForm/FormWrapper";
import Form from "../components/Form";

const SignUpTcpaE = ({ data }) => {
  const trackingId =
    (typeof window !== "undefined" &&
      window.location.pathname
        .replace(/^[/]/, "")
        .replace(/\/$/, "")
        .replaceAll(/[/]/g, "-")
        .concat("-fg")) ||
    "sign-up-form-fg";
  return (
    <Layout templateName="sign-up-form">
      <Seo
        title="Join The Travel Nursing Program Today! - TravelNursing.org"
        description="High-Paying Opportunities for Travel Nurses. See Where You Can Go Today"
        hardcodedCanonicalUrl="https://www.travelnursing.org/sign-up-tcpa-e"
        schemaType="WebPage"
      />
      <FormWrapper fluid={data.bgImage.childImageSharp.fluid}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form
            id="fg-sign-up-form"
            formId={"6751f35146967c4a48479af0"}
            trackingId={trackingId}
            v3={true}
          />
        </div>
      </FormWrapper>
    </Layout>
  );
};

export default SignUpTcpaE;

export const query = graphql`
  query SignUpTcpaE {
    bgImage: file(relativePath: { eq: "form-beach-background.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
